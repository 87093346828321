var svg = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" class="abcjs-pause-svg">
  <g>
    <rect width="8.23" height="25"/>
    <rect width="8.23" height="25" x="17"/>
  </g>
</svg>
`

module.exports = svg
