var svg = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
  <g>
    <polygon points="5 12.5 24 0 24 25"/>
    <rect width="3" height="25" x="0" y="0"/>
  </g>
</svg>
`

module.exports = svg
